/* eslint-disable */
import React, { PropsWithChildren } from 'react';
import { graphql } from 'gatsby';
import { render } from 'datocms-structured-text-to-plain-text';
import { StructuredTextDocument } from 'react-datocms';
import Container from '../../../primitives/grid/container';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import { colors } from '../../../primitives/colors';
import Check from '../pricing/check.svg';
import Info from './info.svg';
import Tooltip from '../tooltip';

export type ComparisonTableProps = Queries.ComparisonTablePropsFragment;

const MobileTooltipCell = ({ children }: PropsWithChildren): JSX.Element => (
    <>
        <div css={{
            [breakpoints.mb]: {
                display: 'none',
            },
        }}
        >
            {children}
        </div>
        <div css={{
            display: 'none',
            [breakpoints.mb]: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        }}
        >
            <Tooltip title={children}>
                <Info />
            </Tooltip>
        </div>
    </>
);

const createDastFromChild = (dast, childIndex) => {
    if (dast) {
        const child = dast.document?.children?.[childIndex];
        if (child) {
            return {
                schema: 'dast',
                document: {
                    children: [child],
                    type: 'root',
                },
            };
        }
    }
    return null;
};

const ComparisonTable = ({ richTitle, table }: ComparisonTableProps): JSX.Element|null => {
    const buildRows = (items): JSX.Element => (
        <>
            {items.map((row) => {
                let hasHadFirstHeaderEl = false;
                return (
                    <tr css={{
                        borderBottom: `1px solid ${colors.grey50}`,
                    }}
                    >
                        {row.map((cell, idx) => {
                            if (cell[0] === 'th') {
                                const trimmed = cell[1] && render(cell[1]).trim().toLowerCase();
                                if (trimmed) {
                                    const localHasHadFirstHeaderEl = hasHadFirstHeaderEl;
                                    hasHadFirstHeaderEl = true;

                                    const child0 = createDastFromChild(cell[1], 0);
                                    const child1 = createDastFromChild(cell[1], 1);

                                    return (
                                        <>
                                            <th css={{
                                                backgroundColor: colors.primaryLightOrange,
                                                borderTopLeftRadius: borderRadius.large,
                                                borderTopRightRadius: borderRadius.large,
                                                padding: '32px 20px',
                                                width: '26vw',
                                                [breakpoints.tb]: {
                                                    borderTopLeftRadius: !localHasHadFirstHeaderEl ? borderRadius.large : '0',
                                                    borderTopRightRadius: idx === row.length - 1 ? borderRadius.large : '0',
                                                },
                                                [breakpoints.mb]: {
                                                    width: '24vw',
                                                    padding: '18px 10px',
                                                },
                                            }}
                                            >
                                                <Typography
                                                    fontSize={{
                                                        dt: 1420,
                                                    }}
                                                    css={{
                                                        opacity: '0.7',
                                                        textAlign: 'center',
                                                        maxWidth: '276px',
                                                        margin: 'auto',
                                                        display: 'block',
                                                        fontWeight: '300',
                                                        'b, strong, i, em': {
                                                            marginBottom: '16px',
                                                            fontSize: '20px',
                                                            fontWeight: '300!important',
                                                            opacity: 1,
                                                        },
                                                        [breakpoints.mb]: {
                                                            'b, strong, i, em': {
                                                                color: `${colors.primaryDarkPurple}!important`,
                                                                fontSize: '14px',
                                                            },
                                                        },
                                                    }}
                                                    as="span"
                                                    override
                                                >
                                                    {child0 && (
                                                        <CustomStructuredTextRenderer
                                                            data={{ value: child0 }}
                                                        />
                                                    )}
                                                    {child1 && (
                                                        <div css={{
                                                            [breakpoints.mb]: {
                                                                display: 'none',
                                                            },
                                                        }}
                                                        >
                                                            <CustomStructuredTextRenderer
                                                                data={{ value: child1 }}
                                                            />
                                                        </div>
                                                    )}
                                                </Typography>
                                            </th>
                                            {(idx !== row.length - 1 && idx !== 0) && (
                                                <th
                                                    css={{
                                                        width: '20px',
                                                        [breakpoints.tb]: {
                                                            display: 'none',
                                                        },
                                                    }}
                                                    aria-hidden="true"
                                                />
                                            )}
                                            {(idx === row.length - 1) && (
                                                <th
                                                    css={{
                                                        display: 'none',
                                                        width: '5vw',
                                                        [breakpoints.mb]: {
                                                            display: 'table-cell',
                                                        },
                                                    }}
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </>
                                    );
                                }
                                return (
                                    <>
                                        <th aria-hidden="true" />
                                        {(idx !== row.length - 1 && idx !== 0) && (
                                            <th
                                                css={{
                                                    width: '20px',
                                                    [breakpoints.tb]: {
                                                        display: 'none',
                                                    },
                                                }}
                                                aria-hidden="true"
                                            />
                                        )}
                                        {(idx === row.length - 1) && (
                                            <th
                                                css={{
                                                    display: 'none',
                                                    width: '5vw',
                                                    [breakpoints.mb]: {
                                                        display: 'table-cell',
                                                    },
                                                }}
                                                aria-hidden="true"
                                            />
                                        )}
                                    </>
                                );
                            }

                            if (cell[1]) {
                                const trimmed = render(cell[1]).trim().toLowerCase();
                                if (trimmed === 'yes' || trimmed === 'true') {
                                    return (
                                        <>
                                            <td
                                                css={{
                                                    borderRight: `1px solid ${colors.grey50}`,

                                                }}
                                                aria-label="Yes"
                                            >
                                                <Check css={{
                                                    width: '26px',
                                                    height: '26px',
                                                    margin: 'auto',
                                                }}
                                                />
                                            </td>
                                            {(idx !== row.length - 1 && idx !== 0) && (
                                                <td
                                                    css={{
                                                        width: '20px',
                                                        borderRight: `1px solid ${colors.grey50}`,
                                                        [breakpoints.tb]: {
                                                            display: 'none',
                                                        },
                                                    }}
                                                    aria-hidden="true"
                                                />
                                            )}
                                            {(idx === row.length - 1) && (
                                                <td
                                                    css={{
                                                        display: 'none',
                                                        width: '5vw',
                                                        [breakpoints.mb]: {
                                                            display: 'table-cell',
                                                        },
                                                    }}
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </>
                                    );
                                }
                            }

                            return (
                                <>
                                    <td css={{
                                        borderRight: `1px solid ${colors.grey50}`,
                                        padding: '20px 0',
                                    }}
                                    >
                                        {idx !== 0 ? (
                                            <>
                                                {cell[1] && (
                                                    <MobileTooltipCell>
                                                        <Typography
                                                            css={{
                                                                opacity: '0.7',
                                                                textAlign: 'center',
                                                                display: 'block',
                                                                fontSize: '14px',
                                                                padding: '0 16px',
                                                                'b, strong, i, em': {
                                                                    marginBottom: '16px',
                                                                    opacity: 1,
                                                                    color: colors.primaryDarkPurple,
                                                                },
                                                                [breakpoints.mb]: {
                                                                    padding: '0 8px',
                                                                },
                                                            }}
                                                            as="span"
                                                            override
                                                        >
                                                            {cell[1] && <CustomStructuredTextRenderer data={{ value: cell[1] }} />}
                                                        </Typography>
                                                    </MobileTooltipCell>
                                                )}
                                            </>
                                        ) : (

                                            <Typography
                                                css={{
                                                    opacity: '0.7',
                                                    textAlign: 'left',
                                                    display: 'block',
                                                    fontSize: '14px',
                                                    'b, strong, i, em': {
                                                        marginBottom: '16px',
                                                        opacity: 1,
                                                        color: colors.primaryDarkPurple,
                                                    },
                                                }}
                                                as="span"
                                                override
                                            >
                                                {cell[1] && (
                                                    <div css={{
                                                        paddingRight: '16px',
                                                        [breakpoints.mb]: {
                                                            paddingLeft: '5vw',
                                                            paddingRight: '8px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            gap: '6px',
                                                        },
                                                    }}
                                                    >
                                                        <CustomStructuredTextRenderer cleanTags data={{ value: createDastFromChild(cell[1], 0) }} />
                                                        {createDastFromChild(cell[1], 1) && (
                                                            <MobileTooltipCell>
                                                                <CustomStructuredTextRenderer cleanTags data={{ value: createDastFromChild(cell[1], 1) }} />
                                                            </MobileTooltipCell>
                                                        )}
                                                    </div>
                                                )}
                                            </Typography>

                                        )}

                                    </td>
                                    {(idx !== row.length - 1 && idx !== 0) && (
                                        <td
                                            css={{
                                                width: '20px',
                                                borderRight: `1px solid ${colors.grey50}`,
                                                [breakpoints.tb]: {
                                                    display: 'none',
                                                },
                                            }}
                                            aria-hidden="true"
                                        />
                                    )}
                                    {(idx === row.length - 1) && (
                                        <td
                                            css={{
                                                display: 'none',
                                                width: '5vw',
                                                [breakpoints.mb]: {
                                                    display: 'table-cell',
                                                },
                                            }}
                                            aria-hidden="true"
                                        />
                                    )}
                                </>
                            );
                        })}
                    </tr>
                );
            })}
        </>
    );

    if (!table) {
        return null;
    }

    const parsedTable = JSON.parse(table);

    return (
        <Container
            css={{
                marginTop: '110px',
                marginBottom: '96px',
                [breakpoints.mb]: {
                    marginTop: '56px',
                    marginBottom: '48px',
                },
            }}
            breakpoints={{
                dt: { strength: 'full' },
                mb: { strength: 'none' },
            }}
        >
            <Typography
                fontSize={{
                    dt: 4053,
                    tb: 3234,
                    mb: 2429,
                }}
                override
                css={{
                    textAlign: 'center',
                    marginBottom: '78px',
                    [breakpoints.mb]: {
                        marginBottom: '38px',
                    },
                }}
                fontWeight="light"
            >
                <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
            </Typography>
            <div css={{
                width: '100%',
                overflowX: 'auto',
            }}
            >
                <table
                    css={{
                        width: '100%',
                        borderCollapse: 'collapse',
                    }}
                >
                    {parsedTable.hasHeader && (
                        <thead>{buildRows(parsedTable.thead)}</thead>
                    )}
                    <tbody>{buildRows(parsedTable.tbody)}</tbody>
                </table>
            </div>
        </Container>
    );
};

export default ComparisonTable;

export const query = graphql`
    fragment ComparisonTableProps on DatoCmsComparisonTable {
        originalId
        richTitle {
            blocks
            links
            value
        }
        table
    }
`;
